import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAggregateSupportByClientId, fetchSupportRequestsByAccountId } from 'helpers/api';
import { SupportRequestInfo } from '../type';
import { Account, ResponseError } from '@my-account/account';
import { productFeatureFlag } from '../../../configs/FeatureFlags';

export type RequestListState = {
  fetch: {
    isLoading: boolean;
    isError: boolean;
    data: SupportRequestInfo[];
    error: ResponseError;
  };
};

export const initialState: RequestListState = {
  fetch: {
    isLoading: false,
    isError: false,
    data: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const fetchOpenSupportRequestsAsync = createAsyncThunk<
  SupportRequestInfo[],
  Account['clientId'],
  { rejectValue: ResponseError }
>('account/fetchOpenSupportRequestsById', async (accountId, thunkApi) => {
  try {
    let data;
    if (productFeatureFlag.isPremiumHelpLinkDisplay() && localStorage.getItem('isPremiumCustomer') === 'true') {
      data = await fetchAggregateSupportByClientId(accountId);
    } else {
      data = await fetchSupportRequestsByAccountId(accountId);
    }
    return data;
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const slice = createSlice({
  name: 'digital-case',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchOpenSupportRequestsAsync.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchOpenSupportRequestsAsync.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(fetchOpenSupportRequestsAsync.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });
  },
});
export const requestListReducer = slice.reducer;
