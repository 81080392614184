import { ArrowRightIcon } from '@myob/myob-widgets';
import { TileWrapper } from './styles';

export type TileProps = {
  title: string;
  description: string;
  image: string;
  link: string;
  linkText: string;
  className?: string;
  onClick: (url: string, title?: string) => void;
  disabled?: boolean;
};

const Tile: React.FC<TileProps> = ({ disabled, onClick, className, title, description, image, link, linkText }) => {
  const handleClick = (link: TileProps['link'], linkText: TileProps['linkText']) => {
    !disabled && onClick(link, linkText);
  };

  return (
    <TileWrapper className={className}>
      <div className="tile-info">
        <img className="tile-info-image" src={image} alt={`${title} tile image`} />
        <div className="tile-info-content">
          <h2 className="tile-info-content__title">{title}</h2>
          <p className="tile-info-content__description">{description}</p>
        </div>
      </div>
      <a
        tabIndex={0}
        className={`tile-link ${disabled ? 'disabled' : ''}`}
        role="button"
        onClick={() => handleClick(link, linkText)}
        onKeyDown={() => handleClick(link, linkText)}
      >
        <span>{linkText}</span>
        <i className="tile-link__icon">
          <ArrowRightIcon color="brand" />
        </i>
      </a>
    </TileWrapper>
  );
};

export default Tile;
