import styled from '@emotion/styled';
import { colors, mediaQueries } from 'styles/variables';

export const TileListWrapper = styled.div`
  width: 100%;

  ${mediaQueries.xLargeUp} {
    width: 76.7rem;
  }
  .tile-list-columns > div {
    margin-bottom: 1.6rem;

    ${mediaQueries.mediumUp} {
      &:nth-of-type(5),
      &:nth-of-type(6) {
        margin-bottom: 0;
      }
    }
  }

  .tile-list-columns > .tile-list-columns__column {
    margin-bottom: 0;
  }
`;
export const HelpLinksWrapper = styled.div`
  min-height: 100px;
  flex-grow: 1;
  margin-top: 1.6rem;
  ${mediaQueries.xLargeUp} {
    margin-left: 1.6rem;
    margin-top: 0;
  }
`;
export const HeaderWrapper = styled.header`
  .first_name {
    margin-bottom: 0;
    color: ${colors.storm100};
  }
  .account-description {
    margin-top: 0.8rem;
  }
`;
export const ContentWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  ${mediaQueries.xLargeUp} {
    flex-direction: row;
  }
`;

export const HelpLinkWrapper = styled.div`
  height: 100%;
  .help-links-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .flx-card__body {
      flex-grow: 1;
    }
    .help-links {
      height: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      .help-link-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        color: ${colors.dusk100};
        &:hover {
          .help-link-item__icon {
            ${mediaQueries.mediumUp} {
              transform: translateX(1.6rem);
            }
          }
        }
        &__icon {
          margin-right: 1.6rem;
          transition-property: transform;
          transition-duration: 0.2s;
          fill: ${colors.dusk100};
        }
      }
    }
  }
`;
export const SupportTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 0.4rem;
  padding: 2rem;
  min-height: 13.2rem;

  .tile-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    ${mediaQueries.mediumUp} {
      flex-direction: row;
    }
    &-content {
      width: 100%;
      &__title {
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
      }
      &__description {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        margin-right: 2rem;
      }

      ${mediaQueries.mediumUp} {
        padding-left: 2rem;
        &__description {
          margin-bottom: 0;
        }
      }
    }
    &-btn {
      padding: 0 4rem;
    }
    &-premium {
      display: flex;
      flex-direction: row;
      &-staricon {
        display: flex;
        align-self: flex-start;
      }
      &-content {
        width: 100%;
        &__title {
          font-size: 1.8rem;
          margin-bottom: 0.8rem;
        }
        &__description {
          font-size: 1.4rem;
          margin-bottom: 1rem;
          margin-right: 2rem;
          &_detail {
            color: ${colors.storm100};
          }
        }

        ${mediaQueries.mediumUp} {
          padding-left: 2rem;
          &__description {
            margin-bottom: 0;
          }
        }
      }
      &-btn {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 8rem;
      }
    }
  }
`;

export const SupportRequestWrapper = styled.div`
  margin-top: 30px;
  .support-request {
    color: ${colors.storm100};
  }

  .view-all-requests {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
  }

  .table-data__container > .loading-content {
    background: ${colors.white};
  }

  .table-data__container > .forbidden-component,
  .table-data__container > .error-component  {
    margin-top: -12px;
    > div:nth-child(2) {
      border-radius: 0px;
    }
`;

export const LoadingSupportTileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 0.4rem;
  padding: 5.5rem;
  min-height: 13.2rem;
`;
