import { Button } from '@myob/myob-widgets';
import { SupportTileWrapper } from '../../styles';
import contactSupportImg from '../../../../assets/images/contact-support.svg';
import { AccountViewRoutePath } from '../../../../type';

export type TileProps = {
  className?: string;
  onClick: (url: string) => void;
};

const CONTACT_SUPPORT = 'Contact support';

const SupportTileItem: React.FC<TileProps> = ({ onClick, className }) => {
  return (
    <SupportTileWrapper className={className}>
      <div className="tile-info">
        <img className="tile-info-image" src={contactSupportImg} alt={`Contact support tile image`} />
        <div className="tile-info-content">
          <h2 className="tile-info-content__title">{CONTACT_SUPPORT}</h2>
          <p className="tile-info-content__description">
            Get help with your software, report a bug or give us feedback.
          </p>
        </div>
        <div className="tile-info-btn">
          <Button
            tone={'brand'}
            data-testid="contact-support-btn"
            onClick={() => onClick(AccountViewRoutePath.Support)}
          >
            {CONTACT_SUPPORT}
          </Button>
        </div>
      </div>
    </SupportTileWrapper>
  );
};

export default SupportTileItem;
